/* utils */
.w100-container {
	width: 100%;
}

.w100-container-complete {
	width: 100% !important;
}

.w32-container {
	width: 32% !important;
}

/* grid container with 3 columns */
.grid-container-3c {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1rem 1rem;
	margin: 0rem 0rem 0.5rem 0rem;
	padding: 0.5rem;
}

/* grid container with 2 columns */
.grid-container-2c {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem 1rem;
	margin: 0rem 0rem 0.5rem 0rem;
}

/* grid container with 1 column */
.grid-container-1c {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	/* margin: 0rem 0rem 0.5rem 0rem; */
}

.zero-gap {
	gap: 0rem;
}

.sm-gap {
	gap: 0.5rem;
}

/* grid container with 1 column with 2 gap  */
.grid-container-1c-2g {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	margin: 0rem 0rem 0.5rem 0rem;
	width: 100%;
}

/* custom grid 2 columns i r  */
.grid-container-2c-ir {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 10rem;
	grid-template-areas: 'other me';
}

.item-co {
	grid-area: other;
}

.item-cm {
	grid-area: me;
}

/* text-inline helps to set the paragraph in their space */
.text-inline,
.text-inline-md,
.text-inline-large {
	display: inline;
	width: 150px;
	font-size: 1rem;
	min-width: 100px;
	padding: 5px;
	color: gray;
	font-weight: var(--font-weight);
}

.text-inline-md {
	width: 25vw;
}

.text-inline-large {
	width: 50vw;
}

/* text-checkout helps to set the paragraph in their space */
.text-checkout {
	display: inline;
	width: 150px;
	font-size: 0.9rem;
	min-width: 100px;
	padding: 0px;
	color: gray;
	font-weight: var(--font-weight);
	margin-left: 1rem !important;
}

/* helps to position checkbox with input */
.label-position {
	position: relative;
	top: -0.5rem;
}

/* container of a h3 and section to a form */
.margin-top {
	margin-top: 15px;
}
.flex-start {
	align-items: flex-start !important;
}
.d-flex {
	display: flex;
	align-items: center;
	/* height: 4.5rem; */
	flex-direction: row;
	width: 100%;
	min-width: 4rem;
}

/* display add justify-content */
.d-ajc {
	justify-content: space-around !important;
}

.footer-grid-buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}

.footer-grid-buttons button {
	margin: 1rem 0rem;
}

/* structure of a large label in 1 row */
.label-large-1r {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	/* margin: 2rem 0rem; */
}

.span-label {
	width: 230px;
	font-size: 1rem;
	margin: 0px;
	color: gray;
	font-weight: var(--font-weight);
}

.center-container-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.p-styles {
	font-size: 1rem;
	color: var(--dark-gray);
	font-weight: var(--font-weight);
}

.margin-05-0 {
	margin: 0.5rem 0rem !important;
}

.margin-top-1 {
	margin-top: 1rem !important;
}

.margin-bottom-1 {
	margin-bottom: 1rem !important;
}

.margin-bottom-2 {
	margin-bottom: 2rem !important;
}

.margin-right-1 {
	margin-right: 1rem !important;
}

.margin-left-1 {
	margin-left: 1rem !important;
}

.p-review {
	font-size: 0.9rem;
	/* margin-bottom: 1rem !important; */
	/* margin-left: 1rem !important; */
	/* margin-right: 1rem !important; */
	color: var(--color-primary);
	font-weight: var(--font-weight);
	cursor: pointer;
}

.title-modal {
	color: var(--color-primary);
	font-weight: bold;
	font-size: 1.2rem;
}

/* btn-cancel items */
.btn-action-cancel {
	background: white;
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: #58585b;
	font-weight: bold;
	height: fit-content;
}

/* btn-action */
.btn-action-primary {
	height: fit-content;
	background: linear-gradient(113.52deg, #ffc954 34.84%, #e9b440 122.42%);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border: 0;
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: bold;
	/* width: fit-content; */
	/* min-width: 300px; */
	cursor: pointer;
	/* max-width: 200px; */
}

/* Normal input styles */
.input-default {
	height: 36px !important;
	margin: 1rem 0rem;
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: 400;
	min-width: 250px;
	width: 80%;
	/* height: 1rem; */
}

/* Normal input styles */
.input-default-3c {
	height: 36px !important;
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: 400;
	min-width: 150px;
	width: 100%;
	margin: -4px;
	margin-top: 7px;
	margin-bottom: 7px;
}

textarea, select{
	margin-top: 7px;
	margin-bottom: 7px;
}

.input-default-disable {
	background: var(--inactive-color);
	color: var(--white);
}

/* Styles for dateTime input */
.input-date {
	/* height: 36px !important; */
	margin: 0rem 0rem;
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: 400;
	width: 45%;
	height: 2rem;
	/* max-width: 50px; */
}

.input-textarea {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 10px;
	padding: 0.5rem 2rem;
	color: var(--text-dark);
	font-weight: 400;
	font-family: inherit;
	min-width: 250px;
	width: 100%;
	height: 4rem;
	resize: none;
	overflow: visible;
}

.input-textarea-sm {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 10px;
	padding: 0.5rem 2rem;
	color: var(--text-dark);
	font-weight: 400;
	font-family: inherit;
	min-width: 250px;
	width: 80%;
	height: 4rem;
	resize: none;
	overflow: visible;
}

.box-text-container {
	width: 100px;
	align-self: center;
	text-align: left;
}

/* padding globals */
.padding-all-1 {
	padding: 1rem;
}

.container-technical-association {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-around;
}

.container-description {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

@media (max-width: 1200px) {
	.grid-container-3c {
		grid-template-columns: 1fr 1fr;
		gap: 1.5rem 1rem;
	}
}

@media (max-width: 1050px) {
	.grid-container-2c {
		grid-template-columns: 1fr;
		gap: 3rem 1rem;
		margin: 1.5rem 0rem 0.5rem 0rem;
	}

	.d-flex {
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: auto;
	}

	.text-inline,
	.text-inline-md,
	.text-inline-large {
		width: 100%;
		text-align: center;
	}

	.input-default {
		width: 100%;
	}

	.input-default-3c {
		width: 100%;
	}

	.footer-grid-buttons {
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		margin: 1rem 0rem;
	}

	.span-label {
		width: 180px;
	}

	.container-technical-association {
		flex-direction: column;
	}

	.container-description {
		flex-direction: column;
	}

	.box-text-container {
		text-align: center;
		width: 100%;
	}

	.label-position {
		position: relative;
		top: -0.9rem;
	}
}

@media (max-width: 660px) {
	.title-modal {
		margin-bottom: 1rem !important;
	}

	.input-default {
		min-width: 100%;
	}

	.grid-container-3c {
		grid-template-columns: 1fr;
	}
}

/* Color for Report texts */
.mid-green {
	color: #0aa48a;
}
