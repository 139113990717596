:root {
	--color-primary: #0aa48a;
	--text-primary: #0ed6b5;
	--text-secondary: #018e82;
	--text-dark: #58585b;
	--background-primary: #f4bf4a;
	--background-primary-linear: linear-gradient(113.52deg, #ffc954 34.84%, #e9b440 122.42%);
	--background-gray: #555555;
	--black: #000;
	--light-gray: lightgray;
	--dark-gray: #7e8491;
	--white: white;
	--error: #e21e26;
	--active-color: #a3bd31;
	--inactive-color: #8b909c;
	--blue-color: #009bdb;
	--font-weight: 700;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	border-style: none !important;
	border-width: 0px !important;
	border: 0px !important;
	border-color: transparent !important;
	border-radius: 4rem !important;
	/* height: 52px; */
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2) !important;
	padding: 0 6px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 7px !important;
}

.css-ccgz37-MuiAutocomplete-root .MuiOutlinedInput-root {
	padding: 3px 0px 0px 7px !important;
}

.css-ccgz37-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
	padding: 2px 0px 6px 8px !important;
}

.primary-green {
	color: var(--color-primary) !important;
}

.dark-gray {
	color: var(--text-dark) !important;
}

/* .css-1rhbuit-multiValue {
  color: var(--white);
  background-color: var(--color-primary) !important;
  border-radius: 3rem !important;
}

.css-12jo7m5 {
  color: var(--white) !important;
}

.css-g1d714-ValueContainer {
  overflow: scroll !important;
  height: fit-content;
  max-height: 5rem;
  overflow-x: hidden !important;
  padding: 0px 30px !important;
} */

.disable-scrollbars::-webkit-scrollbar {
	background: transparent;
	width: 0px;
}

.disable-scrollbars {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.css-b62m3t-container {
	height: 2.4rem;
}
.input-error {
	margin-top: 5px;
	color: red;
}

/* 
 */
/* .css-1s2u09g-control,
.css-1pahdxg-control {
  border: 0px !important;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  height: 40px;
  padding: 0 6px;
  max-width: 20rem;
  min-width: 225px;
  box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
  border-radius: 70px !important;
  height: fit-content;
  width: 100%;
} */

/* .css-14el2xx-placeholder,
.css-1s2u09g-control {
  font-family: "Roboto", sans-serif;
  color: var(--text-dark) !important;
  font-size: 0.75rem;
  font-weight: 400;
  width: 100%;
} */

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Global styles */
.btn-primary {
	background: linear-gradient(113.52deg, #ffc954 34.84%, #e9b440 122.42%);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border: 0;
	border-radius: 50px;
	padding: 0.5rem 3rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: bold;
	width: fit-content;
	min-width: 300px;
	cursor: pointer;
	max-width: 200px;
}

.btn-secondary {
	filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
	background: #0aa48a;
	/* box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2); */
	border: 0;
	border-radius: 50px;
	padding: 0.5rem 3rem;
	font-family: inherit;
	color: var(--white);
	font-weight: bold;
	width: fit-content;
	min-width: 180px;
	cursor: pointer;
	max-width: 200px;
}

.table-minimal-dnd {
	cursor: grab;
}

.table-minimal-dnd:active {
	cursor: grabbing;
}

.btn-primary-center {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: auto;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.div-button-ol {
	margin-left: 50%;
}

.input-primary {
	height: 36px !important;
	margin: 1rem 0rem;
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: 400;
	min-width: 150px;
	width: 80%;
	/* height: 1rem; */
}

.input-primary-textarea {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 10px;
	padding: 0.5rem 2rem;
	color: var(--text-dark);
	font-weight: 400;
	font-family: inherit;
	min-width: 250px;
	width: 85%;
	height: 4rem;
	resize: none;
	overflow: visible;
	/* margin-bottom: 1rem; */
}

.input-primary-textarea::placeholder {
	font-family: inherit;
	font-size: 0.9rem;
	color: var(--text-dark);
}

.input-select {
	width: 250px;
	height: 2rem;
	font-family: inherit;
	border: 0;
	box-shadow: 0px 4px 8px rgb(0 174 142 / 20%);
	border-radius: 50px;
	padding: 0.5rem 2.5rem;
	background: var(--white);
	color: var(--text-dark);
	min-width: fit-content;
}

.input-select-small {
	width: 225px;
	height: 2rem;
	font-family: inherit;
	border: 0;
	box-shadow: 0px 4px 8px rgb(0 174 142 / 20%);
	border-radius: 50px;
	padding: 0.5rem;
	background: var(--white);
	color: var(--text-dark);
	text-align: start;
}

.pass-wrapper {
	position: relative;
}

.input-passwords {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	color: var(--text-dark);
	font-family: inherit;
	font-weight: 400;
	min-width: 250px;
	width: 350px;
	height: 2rem;
	margin-top: 2rem;
}

.input-other-pswd {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	color: #58585b;
	font-family: inherit;
	font-weight: 400;
	width: 20rem;
	min-width: fit-content;
	height: 1rem;
	margin-top: 2rem;
}

/* .input-error {
  border: 1px solid red;
} */

.w-100 {
	width: 100% !important;
}

.spacing-1 {
	margin-top: 1rem;
}

.spacing {
	margin-top: 2rem;
}

.spacing-r1 {
	margin-right: 1rem;
}

.spacing-l1 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.spacing-t1 {
	margin-top: 1rem;
}

.spacing-b1 {
	margin-bottom: 0.5rem;
}

.spacing-footer {
	margin-top: 0.5rem !important;
}

.btn-scroll {
	color: var(--light-gray);
}

button,
.pointer {
	cursor: pointer;
}

.global-select {
	background-color: var(--color-primary);
	padding: 0.4rem 2.5rem;
	color: var(--white);
	font-family: inherit;
	border: 0px solid transparent;
	border-radius: 50px;
}

.btn-option {
	background-color: var(--color-primary);
	border-radius: 45px;
	z-index: 1;
	border: 0px;
	padding: 0.5rem 1rem;
	font-family: inherit;
	font-weight: 600;
	color: var(--white);
	margin-bottom: 0.2rem;
	margin-left: 0.2rem;
}

/* .btn-upload {
  background-color: var(--color-primary);
  border-radius: 45px;
  z-index: 1;
  margin-left: 2;
  border: 0px;
  padding: 0.5rem 1rem;
  font-family: inherit;
  font-weight: 600;
  color: var(--white);
  margin-left: 0.2rem;
  cursor: pointer;
} */

.btn-upload:active {
	background-color: var(--text-secondary);
}

.disabledInput {
	display: none;
}

.btn-search {
	background-color: var(--color-primary);
	border-radius: 45px;
	z-index: 1;
	border: 0px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	font-weight: 600;
	color: var(--white);
	margin-left: 0.2rem;
	font-size: 0.8rem;
	align-self: center;
	cursor: pointer;
}

.btn-clear-form {
	background-color: #8b909c;
	border-radius: 45px;
	z-index: 1;
	border: 0px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	font-weight: 600;
	color: var(--white);
	margin-left: 0.2rem;
	font-size: 0.8rem;
	align-self: center;
	cursor: pointer;
}

.btn-export,
.btn-upload,
.btn-add {
	background-color: var(--color-primary);
	border-radius: 45px;
	z-index: 1;
	border: 0px;
	padding: 0.5rem 1rem;
	font-family: inherit;
	font-weight: 600;
	color: var(--white);
	margin-left: 0.2rem;
	font-size: 0.8rem;
	align-self: center;
	cursor: pointer;
}

.btn-disabled {
	background-color: #d3d3d3; /* Cambia el color de fondo para indicar deshabilitado */
	color: #808080; /* Cambia el color del texto */
	cursor: not-allowed; /* Cambia el cursor a "no permitido" */
	opacity: 0.6; /* Ajusta la opacidad para indicar deshabilitado */
	border: 0;
	border-radius: 50px;
	padding: 0.5rem 3rem;
	font-family: inherit;
	font-weight: bold;
	width: fit-content;
	min-width: 300px;
	max-width: 200px;
}

.btn-add-yellow {
	background-color: var(--background-primary);
	border-radius: 45px;
	z-index: 1;
	border: 0px;
	padding: 0.5rem 1rem;
	font-family: inherit;
	font-weight: 600;
	color: var(--text-dark);
	margin-left: 0.2rem;
	font-size: 0.8rem;
	align-self: center;
	cursor: pointer;
}

.btn-wrap-add {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 0.5rem 2rem;
}

.btn-loading {
	background-color: var(--background-gray);
}

.width-100 {
	width: calc(100vw - 1rem);
	margin-left: 1rem;
}

.width-85 {
	width: calc(85vw - 20rem);
	margin-left: 1rem;
}

.width-80 {
	width: 60vw;
	margin-left: 1rem;
}

.width-70i {
	width: 70% !important;
}

.width-70 {
	width: calc(70vw - 30rem);
	margin-left: 1rem;
}

.width-60 {
	width: calc(60vw - 30rem);
	margin-left: 1rem;
}

.width-50 {
	width: calc(50vw - 30rem);
	margin-left: 1rem;
}

.width-40i {
	width: 40% !important;
}

.margin-auto {
	margin: auto;
	margin-bottom: 1rem;
}

.width-100-fix {
	background: linear-gradient(113.52deg, #ffc954 34.84%, #e9b440 122.42%);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border: 0;
	border-radius: 50px;
	padding: 0.5rem 3rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: bold;
	min-width: 300px;
	cursor: pointer;
	max-width: 200px;
	width: 100%;
	margin-top: 1rem;
}

.input-cancel {
	background: white;
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: #58585b;
	font-weight: bold;
	height: fit-content;
}

/* selector disabled */
/* .css-1insrsq-control {
  width: 300px;
}

.css-1s2u09g-control {
  width: 100%;
}

.select-wrapper-selects {
  width: auto;
} */

.select-select-responsive {
	width: 100%;
	align-self: auto;
	margin-top: 1rem;
}

.select-wrapper-container {
	width: auto;
	align-self: auto;
	margin-top: 1rem;
}

@media (max-width: 1050px) {
	.select-wrapper-container {
		width: auto;
	}

	.input-select-small {
		width: 50%;
	}

	/* .css-1s2u09g-control {
    width: 100%;
    margin: auto;
  }

  .css-1insrsq-control {
    width: 100%;
  } */
}

@media (max-width: 937px) {
	.width-70i {
		width: 100% !important;
	}
}

@media (max-width: 900px) {
	/* .select-wrapper-container {
    width: 100%;
  }

  .css-1s2u09g-control {
    width: 100%;
    margin: auto;
  }

  .css-1insrsq-control {
    width: 100%;
  } */
}

@media only screen and (max-width: 800px) {
	.btn-upload,
	.btn-upload-loading {
		margin: 0.5rem 0rem !important;
	}

	.btn-export,
	.btn-add {
		margin: 0.5rem 0rem;
	}

	.width-80 {
		width: fit-content;
		margin-left: 1rem;
	}
}

@media only screen and (max-width: 780px) {
	.btn-primary {
		min-width: 40%;
	}

	.input-primary {
		width: 50%;
	}

	.input-passwords {
		width: 50%;
	}

	.input-select-small {
		width: 50%;
	}

	/* .select-wrapper-container {
    width: 100%;
  }

  .css-1s2u09g-control {
    width: 100%;
    margin: auto;
  }

  .css-1insrsq-control {
    width: 100%;
  } */
}

input[type='search'] {
	border: none;
	background: var(--white);
	margin: 0rem 0rem 1rem 0.5rem;
	padding: 7px 8px;
	font-size: 13px;
	color: inherit;
	border-radius: 50px 0px 0px 50px;
}

input[type='search']::placeholder {
	color: #bbb;
}

.search-modules-btn {
	border: 0px;
}

button[class='search-modules-btn'] {
	text-indent: -999px;
	overflow: hidden;
	width: 40px;
	padding: 7px 8px;
	margin: 0;
	border-radius: 0px 50px 50px 0px;
	background: var(--background-primary)
		url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' fill='white' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
		no-repeat center;
	cursor: pointer;
}

button[class='search-modules-btn']:hover {
	opacity: 1;
}

button[class='search-modules-btn']:focus,
input[type='search']:focus {
	box-shadow: 0 0 3px 0 #1183d6;
	outline: none;
}

@media only screen and (max-width: 460px) {
	/* .css-1pahdxg-control {
    width: 80vw;
  } */
}

.input-required {
	position: relative;
	color: transparent;
	background-color: transparent;
	top: -25px;
	left: 25px;
	width: 1px;
	z-index: -1;
	border: 0px;
}

.editor {
	width: 800px;
}

.editorText {
	border: solid rgba(128, 128, 128, 0.35);
	border-width: 1px;
	border-radius: 2px;
}

.descriptionContainer {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.descriptionContainer div {
	margin: 2px;
	text-align: left;
}

.selectsContainer {
	display: flex;
	flex-direction: row;
}

.selectsContainer label {
	align-items: center;
	margin: 2px 0px;
}

.externalElementsContainer {
	display: flex;
	flex-direction: row;
	align-content: center;
	margin: 15px 0px 0px 8px;
}

.textStyles {
	font-style: normal !important;
	font-weight: normal;
}

.tableContainer {
	margin: 2px 8px;
}

.tdR,
.thR {
	padding: 4px 4px !important;
	border-top: 0px !important;
	border-right: 0px !important;
	border-bottom: 0px !important;
	border-left: 0px !important;
}

@media only screen and (max-width: 1235px) {
	.editor {
		width: 600px;
		margin-top: 10px;
	}

	.selectsContainer {
		display: flex;
		flex-direction: column;
	}

	.descriptionContainer {
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
	}
}

@media only screen and (max-width: 1040px) {
	.editor {
		width: 700px;
		margin-top: 10px;
	}
}

@media only screen and (max-width: 940px) {
	.editor {
		width: 600px;
		margin-top: 10px;
	}

	.selectsContainer {
		display: flex;
		flex-direction: column;
	}

	.descriptionContainer {
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
	}
}

@media only screen and (max-width: 900px) {
	.editor {
		width: 600px;
		margin-top: 10px;
	}
}

@media only screen and (max-width: 800px) {
	.editor {
		width: 550px;
		margin-top: 10px;
	}
}

@media only screen and (max-width: 700px) {
	.editor {
		width: 450px;
		margin-top: 10px;
	}
}

@media only screen and (max-width: 600px) {
	.editor {
		width: 390px;
		margin-top: 10px;
	}
}

@media only screen and (max-width: 500px) {
	.editor {
		width: 300px;
		margin-top: 10px;
	}

	.externalElementsContainer {
		width: 370px;
	}

	.externalElementsContainer label {
		width: 100px;
	}
}

@media only screen and (max-width: 430px) {
	.externalElementsContainer {
		width: 330px;
	}

	.externalElementsContainer label {
		width: 95px;
	}
}

@media only screen and (max-width: 430px) {
	.externalElementsContainer {
		width: 370px;
		flex-direction: column;
		align-content: center;
	}

	.externalElementsContainer label {
		width: 370px;
		align-items: center;
	}
}

@media only screen and (max-width: 400px) {
	.editor {
		width: 270px;
		margin-top: 10px;
	}

	.externalElementsContainer {
		width: 335px;
		flex-direction: column;
		align-content: center;
	}

	.externalElementsContainer label {
		width: 335px;
		align-items: center;
	}
}

@media only screen and (max-width: 300px) {
	.editor {
		width: 180px;
		margin-top: 10px;
	}

	.selectsContainer select {
		width: 180px !important;
	}

	.externalElementsContainer {
		width: 240px;
		flex-direction: column;
		align-content: center;
	}

	.externalElementsContainer label {
		width: 240px;
		align-items: center;
	}

	.demo-form__footer {
		overflow: auto;
	}
}

.table-style {
	padding: 0.5rem 0.5rem !important;
	border-top: 0rem !important;
	border-right: 0rem !important;
	border-bottom: 0rem !important;
	border-left: 0rem !important;
}

.assign-table-styles {
	display: flex;
	flex-direction: column;
	margin-left: 2rem;
	margin-top: 1rem;
	justify-content: space-between;
}

.assign-table-divs {
	display: flex;
	margin-left: 0.5rem;
	margin-top: 1rem;
	align-items: flex-end;
}
