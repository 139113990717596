.error {
	font-size: 0.7rem;
	color: red;
	margin-right: 470px;
}

.errorName {
	composes: error;
	margin-top: -30px;
	margin-bottom: 13px;
}

.errorDescription {
	composes: error;
	margin-top: -16px;
}

.errorCode {
	composes: error;
	margin-top: -22px;
}
