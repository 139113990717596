.search {
	width: 100%;
	position: relative;
	display: flex;
}

.searchTerm {
	width: 100%;
	border: 3px solid #0aa48a;
	border-right: none;
	padding: 10px;
	height: 36px;
	border-radius: 5px 0 0 5px;
	outline: none;
	color: #9dbfaf;
}

.searchTerm:focus {
	color: black;
}

.searchButton {
	width: 40px;
	height: 36px;
	border: 1px solid #0aa48a;
	background: #0aa48a;
	text-align: center;
	color: #fff;
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
	width: 30%;
	margin-right: 20px;
}

@media only screen and (max-width: 800px) {
	.wrap {
		width: 100% !important;
		margin-right: 0px;
	}

	.searchTerm {
		margin-bottom: 0.5rem;
	}
}
