.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	position: sticky;
	top: 0;
	width: 100%;
	background-color: white;
	z-index: 6;
}
.header-container-menu {
	display: flex;
	align-items: center;
}

.header__menu {
	cursor: pointer;
	margin-left: 1rem;
}

.header__title {
	color: var(--text-primary);
	font-size: 1.6rem;
}

.header__logo {
	width: auto;
	height: 70px;
	margin-left: 1rem;
}

.header-avatar {
	cursor: pointer;
	margin-right: 1rem;
	height: 48px;
	width: 48px;
	border-radius: 50%;
	object-fit: cover;
}

.header-profile {
	position: absolute;
	right: 0rem;
	top: 5rem;
	background-color: var(--white);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	width: 14rem;
	min-width: 100px;
}

.btn-profile p {
	width: 7.5rem;
	text-align: left;
	margin-left: 1rem !important;
}

.btn-profile {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	border: 0px;
	color: var(--dark-gray);
	background-color: var(--white);
	font-size: 1rem;
	padding: 0.5rem;
	font-family: inherit;
	font-weight: 500;
	width: 100%;
	cursor: pointer;
}

.header__options {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header__options h3 {
	color: var(--text-dark);
	font-size: 1.2rem;
	font-weight: bold;
	margin-right: 0.5rem;
}

.btn-profile:hover {
	background-color: var(--color-primary);
	color: var(--white);
}

@media only screen and (max-width: 800px) {
	.header__menu {
		height: 1.3rem;
	}

	.header__title {
		font-size: 1.3rem;
	}

	.header__logo {
		height: 51px;
		margin-left: 1rem;
	}
	.header-profile {
		top: 4rem;
	}
}

@media only screen and (max-width: 607px) {
	.header-profile {
		top: 5.4rem;
	}
}

@media only screen and (max-width: 500px) {
	.header__title {
		font-size: 0rem;
	}

	.header__logo {
		height: 41px;
		margin-left: 0rem;
	}

	.header__options h3 {
		font-size: 1rem;
		margin-right: 0.3rem;
	}

	.header-avatar {
		margin-right: 0.6rem;
		width: 28px;
		height: 28px;
	}

	.header__menu {
		margin-left: 0.3rem;
	}

	.header-profile {
		top: 3rem;
	}
}

@media only screen and (max-width: 300px) {
	.header {
		overflow: auto;
	}
}
