.tab-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
	/*  flex-wrap: wrap; */
}

.new-tab-option {
	width: 100%;
	padding: 0.5rem;
	background: #8b909c;
	border-radius: 10px 10px 0px 0px;
	font-size: 1rem;
	color: white;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.tab-option {
	width: 175px;
	padding: 0.5rem;
	background: #8b909c;
	border-radius: 10px 10px 0px 0px;
	font-size: 1rem;
	color: white;
	outline: none;
}

.tab-option--selected {
	background-color: var(--color-primary);
	font-weight: 700;
	font-size: 1rem;
}

.tab-container {
	border: 2px solid var(--color-primary);
}

.tab-option:hover {
	cursor: pointer;
}

@media (min-width: 789px) {
	.tab-option {
		width: 100%;
	}
}

@media only screen and (max-width: 500px) {
	.tab-option {
		font-size: 0.8rem;
	}
}

@media only screen and (max-width: 460px) {
	.new-tab-option {
		border-radius: 0px;
	}
}

@media only screen and (max-width: 300px) {
	.tab-option {
		font-size: 0.6rem;
	}
}
