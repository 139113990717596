.wrapForm {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: inherit;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0rem 1.8rem;
}

.labelSelect {
	width: 500px;
	min-width: 300px;
}

.wrapFormText {
	color: var(--dark-gray);
	font-weight: 700;
	font-size: 1rem;
	margin-right: 10px;
	width: 100px;
	display: inline-block;
}

.dFlex {
	display: flex;
	align-items: center;
	height: 4.5rem;
	flex-direction: row;
	width: 100%;
	min-width: 4rem;
	justify-content: space-between;
}

.btn-action-primary {
	height: fit-content;
	background: linear-gradient(113.52deg, #ffc954 34.84%, #e9b440 122.42%);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border: 0;
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: bold;
	/* width: fit-content; */
	/* min-width: 300px; */
	cursor: pointer;
	/* max-width: 200px; */
}

@media only screen and (max-width: 1315px) {
	.labelSelect {
		width: 400px;
	}
}

@media only screen and (max-width: 1215px) {
	.labelSelect {
		width: 300px;
	}
}
@media only screen and (max-width: 1115px) {
	.labelSelect {
		width: 250px;
	}
}

@media only screen and (max-width: 1050px) {
	.wrapForm {
		height: inherit;
		align-items: center;
		flex-direction: column;
		justify-content: space-around;
	}

	.dFlex {
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: inherit;
	}

	.wrapFormText {
		margin-right: 0px;
		text-align: center;
	}

	.btn-action-primary {
		margin-bottom: 1rem;
	}
}
