.form-create-user {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 5vh;
	margin-bottom: 5vh;
	height: 100%;
}

.container-profile {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.edit-save-button {
	background: linear-gradient(113.52deg, #ffc954 34.84%, #e9b440 122.42%);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border: 0;
	border-radius: 50px;
	padding: 0.5rem 3rem;
	font-family: inherit;
	color: #58585b;
	font-weight: bold;
}

.input-change-password {
	color: #7e8491;
	font-weight: 400;
}

.cancel-save-button {
	background: white;
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: #58585b;
	font-weight: bold;
	margin: 0rem 1rem;
}

.input-edit {
	background-color: white;
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: #58585b;
	font-weight: 400;
	margin: 1rem 0rem;
	width: 100%;
}

.disabled {
	background-color: lightgray !important;
	color: white !important;
}

.switch {
	position: relative;
	display: inline-block;
	width: 2.8rem;
	height: 1rem;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	padding: 0.7rem;
	margin-top: 2px;
	margin-left: 4px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 1rem;
	width: 1rem;
	left: 0px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: rgba(2, 235, 193, 1);
}

input:focus + .slider {
	box-shadow: 0 0 1px rgba(2, 235, 193, 1);
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 2rem;
}

.slider.round:before {
	border-radius: 50%;
}

.button-spacing {
	margin-top: 2rem;
}

@media screen and (max-width: 780px) {
	.container-profile {
		flex-direction: column;
	}
}

.role-item {
	background: #00ae8e;
	border-radius: 50px;
	border: none;
	color: white;
	font-weight: 700;
	font-size: 13px;
	padding: 3px 15px;
	text-align: left;
	display: flex;
	width: fit-content;
	height: fit-content;
	align-items: center;
}

.role-item div {
	border-radius: 100%;
	color: #00ae8e;
	padding: 5px;
	margin-left: 10px;
	text-align: center;
	height: 10px;
	width: 10px;
	display: inline-block;
	background-color: red;
}

.add-role {
	background: #7e8491;
	border-radius: 50px;
	border: none;
	color: white;
	font-weight: 700;
	font-size: 13px;
	padding: 3px 15px;
	text-align: left;
	display: flex;
	width: fit-content;
	align-items: center;
	height: 1.6rem;
}

.inputRole {
	height: 20vh;
	overflow: auto;
}

.inputRole button {
	margin: 5px 5px;
}

.create-user-button {
	border-radius: 50px;
	padding: 0.5rem 2rem;
	border-style: none;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
	background-color: white;
}

/* .create-user-button:hover {
  background-color: #009bdb;
  color: white;
} */

.button-selected {
	background-color: #009bdb;
	color: white;
}

.cancel-save-button:hover {
	background-color: red;
	color: white;
}

.edit-save-button:hover {
	color: white;
}

@media (max-width: 900px) {
	.inputRole {
		width: 100%;
	}
}
