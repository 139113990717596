.form-responsive {
	width: 80%;
	margin: 0 auto;
}

.form-responsive-container-header {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	margin-top: 1rem;
	height: 14rem;
}

.form-responsive-header__img {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.form-responsive-header__img img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	object-fit: cover;
}

.form-responsive-header__img input {
	color: var(--dark-gray);
	text-align: center;
	text-decoration: underline;
	cursor: pointer;
	font-weight: var(--font-weight);
}

.form-responsive-header__name h2 {
	color: var(--dark-gray);
}

.form-responsive-header__name p {
	color: var(--text-primary);
	font-weight: var(--font-weight);
}

.form-responsive-header__language {
	color: var(--dark-gray);
}

.form-responsive-header__language select {
	background-color: var(--color-primary);
	padding: 0.4rem 2.5rem;
	color: var(--white);
	font-family: inherit;
	border: 0px solid transparent;
	border-radius: 50px;
}

.form-responsive-container-information {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 95%;
	margin: auto;
	height: fit-content;
}

.form-responsive-information__option {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 16px;
}

.form-responsive-information__option h3 {
	color: var(--dark-gray);
}

.form-responsive-information__option p {
	color: var(--dark-gray);
	text-align: left;
	text-decoration: underline;
	font-size: 0.8rem;
	cursor: pointer;
	font-weight: 400;
	margin-top: 1rem !important;
}

.form-responsive-information__option select {
	width: 250px;
	height: 2rem;
	font-family: inherit;
	border: 0;
	box-shadow: 0px 4px 8px rgb(0 174 142 / 20%);
	border-radius: 50px;
	padding: 0.5rem 2.5rem;
	background: var(--white);
	color: var(--text-dark);
	min-width: fit-content;
}

.form-responsive-container-buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
}

.form-responsive-container-buttons button {
	margin: 1rem 0rem;
}

.form-responsive-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--inactive-color);
}

.form-responsive-toggle {
	font-size: 16px !important;
	font-weight: bold !important;
	text-decoration: none !important;
	color: var(--dark-gray) !important;
}

@media (max-width: 900px) {
	.form-responsive-container-header {
		text-align: center;
		flex-direction: column;
		height: 400px;
		min-height: min-content;
		overflow: auto;
	}

	.form-responsive-information__option {
		flex-direction: column;
		overflow: auto;
		height: 125px;
		align-items: center;
		justify-content: center;
	}

	.form-responsive-container-buttons {
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		margin: 1rem 0rem;
	}

	.form-responsive-information__option select {
		width: 10%;
		padding: 0rem;
	}
}

@media (max-width: 450px) {
	.form-responsive-container-header {
		height: 300px;
	}

	.form-responsive-header__img img {
		width: 100px;
		height: 100px;
	}

	.form-responsive-header__name h2 {
		font-size: 1rem;
	}

	.form-responsive-header__language h3 {
		font-size: 1rem;
	}
}
