.centered-form {
	margin: 40px 0px;
}

.container-wrapForm {
	width: 90%;
	margin: auto;
}

.error-msg {
	color: var(--error);
	font-size: 0.7rem;
	margin-left: 10px;
}

.error-input {
	color: var(--error);
	font-size: 0.7rem;
	display: flex;
	margin-left: 20px;
	margin-top: -25px;
	margin-right: 800px;
}

.circle-form {
	background-color: var(--color-primary);
	height: 2.8rem;
	width: 2.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: middle;
	color: white;
	font-weight: bold;
	border-radius: 50%;
	/* may require vendor prefixes */
	margin: 1.1rem 1.2rem;
}

.relative-circle-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.relative-circle {
	background-color: var(--color-primary);
	height: 1.2rem;
	width: 1.2rem;
	display: flex;
	border-radius: 50%;
	/* may require vendor prefixes */
	margin: 1.1rem 1.2rem;
}

.delete-relative {
	color: var(--color-primary);
	font-weight: bold;
	cursor: pointer;
}

.title-section {
	display: flex;
	width: 100%;
	align-items: center;
	align-content: center;
	text-align: center;
	align-self: center;
}

.wrapForm {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: inherit;
	justify-content: space-between;
	align-items: flex-start;
	width: 90%;
	padding: 0rem 5rem;
}

.wrapForm-w100 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: inherit;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0rem 1.8rem;
}

.container-wrapForm h2 {
	color: var(--text-dark);
	font-weight: var(--font-weight);
	font-size: 1.2rem;
}

.wrapForm label {
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
}

.wrapForm__label {
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
}

.container-wrapForm__label {
	display: flex;
	/* align-items: flex-end; */
	align-items: center;
	width: 100%;
	flex-direction: column;
}

.input-primary-wrap-toggle {
	min-width: fit-content;
	width: 55vw;
	height: 2rem !important;
	margin-left: 15px;
	justify-content: flex-end;
	align-items: normal;
}

.warpForm-text__toggle,
.warpForm-text {
	color: var(--dark-gray);
	font-weight: var(--font-weight);
	font-size: 1rem;
	margin-right: 10px;
	width: 8.9vw;
	display: inline-block;
}

.input-primary-wrap {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: 400;
	min-width: fit-content;
	width: calc(100% - 240px);
	height: 2rem;
}

.disable-input {
	background: var(--light-gray) !important;
}

.input-primary-wrap-large {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: 400;
	min-width: fit-content;
	width: 55vw;
	height: 2rem !important;
	margin-left: 15px;
}

.form-tab {
	background-color: var(--dark-gray);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px 10px 0px 0px;
	font-weight: normal;
	font-size: 1.2rem;
	color: white;
	border: none;
	padding: 0.6rem 1.5rem;
}

.is-active {
	background-color: var(--color-primary);
	border: 1px solid var(--black);
	text-align: center;
}

.container-border-wrapForm {
	width: 95%;
	border: 1px solid var(--color-primary);
	border-radius: 0px;
	margin-top: 2rem;
}

.container-wrapForm-content {
	width: 100%;
	border: 1px solid var(--color-primary);
	border-radius: 0px 0px 10px 10px;
}

.container-wrap-formB {
	width: 100%;
	border: 1px solid var(--color-primary);
	border-radius: 0px 0px 10px 10px;
	padding: 50px;
}

.container-wrapForm-content-no-border {
	width: 100%;
	margin: 1rem 0rem;
}

.wrap-form-title {
	color: var(--color-primary);
	font-size: 1.2rem;
	margin: 1rem 3rem !important;
	font-weight: var(--font-weight);
}

.title-table {
	color: var(--color-primary);
	font-size: 1.2rem;
	margin: 1rem !important;
	font-weight: var(--font-weight);
	align-self: self-start;
}

.new-container-wrapForm__tabs {
	display: flex;
}

/* FIXME: Esto se debe */
/* @media only screen and (max-width: 1064px) {
  .wrapForm label {
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }
} */

@media only screen and (max-width: 937px) {
	.wrapForm {
		width: 100%;
		flex-direction: column;
		flex-wrap: no-wrap;
		align-items: center;
		justify-content: space-around;
	}

	.wrapForm__label {
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
	}

	.wrapForm label {
		display: flex;
		align-items: center !important;
		justify-content: space-between;
		flex-direction: column;
		width: 100%;
	}

	.warpForm-text {
		width: 240px;
		text-align: center;
	}

	.form-tab {
		font-size: 1rem;
		padding: 0.6rem 1rem;
	}
}

@media only screen and (max-width: 700px) {
	.form-tab {
		width: 100%;
		border-radius: 0px;
	}

	.container-wrapForm__tabs {
		background-color: var(--dark-gray);
		border-radius: 0px;
	}

	.container-wrapForm-content {
		width: auto;
		border-radius: 0px;
	}
}

@media only screen and (max-width: 460px) {
	.wrapForm {
		width: 100%;
		padding: 0rem;
	}

	.warpForm-text {
		font-size: 0.9rem;
	}

	.wrapForm label {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.warpForm-text {
		text-align: center;
		margin: 0px;
	}

	.title-section {
		flex-direction: column;
	}

	.title-section h2 {
		font-size: 1rem;
	}

	.circle-form {
		height: 2rem;
		width: 2rem;
	}

	.container-wrapForm__label {
		display: flex;
		align-items: center;
		width: 100%;
		flex-direction: column;
	}

	.new-container-wrapForm__tabs {
		display: block;
	}

	.input-primary-wrap-large {
		min-width: 85vw;
		margin: 0px;
	}
}

.vehicleInfo {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: inherit;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	padding: 0rem 1.8rem;
	margin-bottom: 2.5rem;
}

@media only screen and (max-width: 330px) {
	.container-wrapForm {
		width: 100%;
		/* margin: auto; */
	}

	.container-wrapForm-content {
		overflow: auto;
	}
}
