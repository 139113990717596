.container__error {
	color: black;
	display: flex;
	height: 100vh;
	font-size: 2em;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 38%;
	margin: auto;
}
.container__error__details {
	animation-name: pulse;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@media (max-width: 1300px) {
	.container__error {
		border: none;
	}
}
