.container {
	margin: 0;
	padding: 0;
	width: 90% !important;
	min-width: 250px !important;
}

.container label {
	margin: -7px 0px 0px 4px !important;
	padding: 0 !important;
	flex-direction: initial !important;
	font-size: 0.9rem !important;
}

.menuItem {
	/* background-color: blueviolet; */
	font-size: 0.9rem !important;
}
.menuList {
	/* background-color: blue; */
	font-size: 0.2rem !important;
}
.noOptions {
	/* background-color: green; */
	font-size: 0.9rem !important;
}
.headerItem {
	background-color: red;
	font-size: 0.9rem !important;
}

.notchedOutline {
	border-radius: 0px !important;
	border-style: none !important;
	border-width: 0px !important;
	border-color: transparent !important;
}

.inputData {
	font-size: 0.9rem !important;
}

@media (max-width: 820px) {
	.container {
		width: 90% !important;
	}
}
