/* grids */
.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 15px;
}

.grid-c2 {
	display: grid;
}

.display-btn-advanced-search {
	display: flex;
	justify-content: start;
	align-items: center;
	margin-top: 10px;
	width: 100%;
}

.margin-btn {
	margin-left: 10px;
	padding: 1rem;
}

/* text styling */
.text-association {
	padding: 0rem 0rem 0rem 1rem;
	color: var(--text-secondary);
	font-weight: bold;
}

@media (max-width: 820px) {
	.grid {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 2rem;
	}
}

@media (max-width: 300px) {
	.grid {
		overflow: auto;
	}
}
