.card__container {
	position: relative;
	z-index: 1;
	border-radius: 8px;
	overflow: hidden;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}

.card__container.card__small_size:hover .card__details {
	transition-delay: 0.3s;
	opacity: 1 !important;
}

.card__content {
	padding: 10px;
	height: 100%;
}

.card__container img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.card__header {
	display: flex;
	justify-content: space-between;
}

.card__content_name p {
	color: #353535;
	font-weight: bold;
	font-size: 14px;
	letter-spacing: 0.3px;
}

.card__details {
	margin-top: 10px;
	height: 100%;
	pointer-events: none;
	transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
}

.card__details li {
	font-size: 13px;
	font-weight: bold;
	letter-spacing: -1px;
}

.card__details li span {
	float: right;
	font-weight: 300;
}
