/* This center a content in the middle of the screen!!! */
.recover-password {
	display: flex;
	align-items: center;
	width: 100%;
	height: 80vh;
	justify-content: center;
	text-align: center;
	flex-direction: column;
}

.recover-password-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	height: 300px;
}

.recover-password-title {
	font-size: 2rem;
	font-weight: 500;
	text-align: center;
	color: var(--text-dark);
}

.back {
	font-family: inherit;
	font-weight: 500;
	text-align: center;
	text-decoration-line: underline;
	color: var(--text-secondary);
	cursor: pointer;
	margin-top: 1rem !important;
}

.error-message li {
	color: var(--error);
	text-align: left;
	list-style: none;
	padding: 0;
	font-weight: 400;
}

.recover-password-container h4 {
	margin-bottom: 20px;
}

.recover-password-container .input-primary .spacing {
	width: 50%;
	max-width: 200px;
	margin-bottom: 15px;
}

.recover-password-container .btn-primary {
	width: 40%;
	max-width: 200px;
	margin-top: 15px;
}

.recover-password-container .back {
	margin-top: 15px;
}
