.gantt__container {
	 overflow-x: scroll; 
	/* overflow-y: auto;  */
	padding: 15px 0px;
	white-space: nowrap;
	/* cursor: grab; */
	/* cursor: -moz-grab;
  cursor: -webkit-grab; */
	display: flex;
	flex-direction: row;
	margin-left: 7px;
	/* border: 1px solid rgba(0, 0, 0, 0.65); */
	border-radius: 1rem;
	 min-width: 45px; 
}

.day__technician_content_hidden {
	display: none;
}

.day__container:active {
	/* cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing; */
}

.day__container::-webkit-scrollbar {
	width: 5px;
	height: 8px;
	position: absolute;
}

.day__container::-webkit-scrollbar-track {
	background: transparent;
}

.day__container::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.day__date_cell {
	width: 1248px;
	border-top: 1px solid #ccc;
	border-right: 1px solid #ccc;
}

.day__container::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.date__header {
	font-size: 14px;
	font-weight: bold;
	color: #0ed6b5;
	font-family: Arial, Helvetica, sans-serif;
	padding: 5px;
	display: flex;
	justify-content: center;
}


.day__content {
	display: grid;
	grid-template-columns: 2fr repeat(24, 1fr);
	width: 1513px;
}

.day__header {

	min-width:1513px;
  max-width: 1683px;
  display: flex;
  flex-direction: row;
}

.tech__header_text {
	display: flex;
	font-size: medium;
	justify-content: center;
	font-weight: bold;
	margin-top: 1.5rem;
}

.hour,
.day__technician_header {
	padding: 10px;
	min-width: 52px;
}

.hour,
.day__technician_header,
.day__container_card,
.day__technician_content {
	min-width: 52px;
	min-height: 20px;
	border: 2px solid #cccccc;
}

.day__technician_header,
.day__technician_content {
	min-width: 265px;
	min-height: 10px;
}

.day__container_card,
.day__technician_content {
	height: 50px;
}

.day__technician_content {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	padding: 0px 8px;
	max-width: 250px;
	white-space: unset;
	word-wrap: break-word;
}

.day__technician_content img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.name__techs {
	display: flex;
	justify-content: start;
	flex-direction: column;
	margin-left: 10px;
}

.day__tech_name,
.day__tech_charge {
	color: #767676;
	font-weight: 600;
}

.day__tech_name {
	color: #0ed6b5;
	font-size: 13px;
}

.day__tech_charge {
	font-size: 13px;
}

.day__header_text {
	color: #767676;
	font-weight: bold;
	font-size: 10px;
	align-items: center;
}

.hour.today .day__header_text {
	color: #0ed6b5;
}

/* Day Event */
.day__event_container {
  user-select: text;
  position: relative;
  cursor: "pointer";
}

.day__event_progress {
	position: absolute;
	left: 0;
	top: 0;
	/* border-radius: 6px; */
	display: flex;
	justify-content: start;
	align-items: baseline;
	overflow: hidden;
	/* -webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px; */
}

.day__event_progress_percentage {
	font-weight: bold;
	font-size: 10px;
	pointer-events: all;
	color: #1c1c1c;
}

@keyframes shake {
	0% {
		transform: translate(0, 0);
	}
	10% {
		transform: translate(-0.5px, 0);
	}
	20% {
		transform: translate(0.5px, 0);
	}
	30% {
		transform: translate(-0.5px, 0);
	}
	40% {
		transform: translate(0.5px, 0);
	}
	50% {
		transform: translate(-0.5px, 0);
	}
	60% {
		transform: translate(0.5px, 0);
	}
	70% {
		transform: translate(-0.5px, 0);
	}
	80% {
		transform: translate(0.5px, 0);
	}
	90% {
		transform: translate(-0.5px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}

.day__event_progress_alert{
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;  
  font-size: 12px;
  color: #FAAF40;
  margin: 7px 0 0 7px;
  rotate: -20deg;
  animation: shake 0.8s ease infinite;
  position: absolute;
  z-index: 2;
}

/* Tooltip */
.day__tooltip {
	opacity: 0;
	pointer-events: all;
	position: absolute;
	left: 0;
	right: 0;
	width: 320px;
	background: #fff;
	z-index: 99;
	padding: 15px;
	top: -5px;
	box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.5);
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
}

.day__tooltip li {
	font-size: 12px;
	font-weight: bold;
	letter-spacing: -0.2px;
}

.day__tooltip li span {
	float: right;
}

.day__event_container:hover .day__tooltip {
	opacity: 1;
}

.day__scroll_container {
	/* overflow-x: scroll; */
	/* overflow-y: hidden;
  white-space: nowrap;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; */
}

.day__button_close {
  position: "absolute";
  right: 10;
  top: 10;
  padding: 8;
  cursor: "pointer";
}