.container {
	display: grid;
	grid-template-columns: repeat(4, 1fr); /* 4 columnas con una fracción igual */
	grid-template-rows: 50px; /* Primera fila con altura de 50px */
	grid-row-gap: 10px; /* Espacio entre filas */
	overflow: auto;
}

.title {
	/* grid-column: 1 / 5; Ocupa todas las columnas */
	font-weight: bold; /* Negrita */
}

.content {
	grid-column: 1 / 5; /* Ocupa todas las columnas */
}
