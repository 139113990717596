.alert-icon {
	position: absolute;
	right: 8rem;
	top: 4.5rem;
	background-color: var(--white);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	width: 12rem;
	min-width: 400px;
	border-radius: 10px 10px 0px 0px;
	z-index: 3;
}

.alert-icon-container-body {
	max-height: 300px;
	overflow: scroll;
	overflow-x: hidden;
}

.alert-icon-container-header {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 2rem;
	background-color: var(--light-gray);
	width: 100%;
	border-radius: 10px 10px 0px 0px;
}

.alert-icon-container-header p {
	font-size: 0.8rem;
	font-weight: 500;
	color: var(--dark-gray);
}

.alert-icon-container-message {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border: 0.5px solid var(--light-gray);
	border-bottom: 0px;
	border-right: 0px;
	border-left: 0px;
	height: 90px;
}

.alert-icon-container-message svg {
	margin: 0rem 1rem;
}

.alert-icon-container-info {
	display: flex;
	flex-direction: column;

	align-items: baseline;
	width: 100%;
}

.alert-icon-description {
	font-size: 0.9rem;
	font-weight: bold;
	color: var(--dark-gray);
	margin-top: 0rem !important;
}

.alert-icon-time {
	font-size: 0.8rem;
	font-weight: bold;
	color: var(--dark-gray);
	margin-top: 0rem !important;
}

.message-to-read:hover {
	cursor: pointer;
	text-decoration: underline;
	color: var(--color-primary);
}

.alert-icon-container {
	height: 30px;
	width: 90px;
}

.icon-bubble {
	cursor: pointer;
	position: relative;
	right: -45px;
	top: -44px;
	border-radius: 50%;
	padding: 5px;
	background-color: var(--color-primary);
	font-size: 10px;
	color: var(--white);
	font-weight: var(--font-weight);
	width: fit-content;
}

.icon-bubble-disable {
	position: relative;
	right: -51px;
	top: -41px;
	border-radius: 50%;
	padding: 0.1rem;
	font-size: 0.8rem;
	display: none;
}

@media only screen and (max-width: 800px) {
	.alert-icon {
		right: 6rem;
		top: 4rem;
		min-width: 250px;
	}

	.alert-icon-container {
		width: 50px;
	}

	.icon-bubble {
		right: -30px;
	}
}

@media only screen and (max-width: 500px) {
	.alert-icon {
		right: 0.5rem;
		top: 3rem;
		min-width: 100vw;
	}

	.alert-icon-container {
		width: 35px;
	}

	.icon-bubble {
		right: -20px;
		font-size: 0.6rem;
	}
}
