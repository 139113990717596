.color-picker-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.btn-container-container {
	padding: 5px;
	background: var(--white);
	border-radius: 1px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: inline-block;
	cursor: pointer;
}

.color-picker-text {
	margin: 1rem !important;
	font-size: 14px;
	color: var(--black);
}

.picker-position {
	position: absolute;
	z-index: 2;
}

.picker-position-cover {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
