.btn-state,
.btn-inactive_state,
.btn-disabled_state {
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	outline: 0;
	border: 0;
	user-select: none;
	vertical-align: middle;
	-webkit-appearance: none;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 500;
	line-height: 1.75;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	min-width: 64px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	color: #fff;
	border-radius: 10px;
	font-size: 12px;
	z-index: 1;
	width: fit-content;
	margin: 0px;
	padding: 0px;
}
.btn-state {
	background-color: var(--active-color);
}

.btn-inactive_state {
	background-color: var(--inactive-color);
}

.btn-disabled_state {
	background-color: var(--background-primary);
	width: 70%;
}

.enabled {
	background-color: white;
	color: black !important;
}

.disabled {
	background-color: rgb(199, 199, 199);
	color: white !important;
}

.userOptions {
	display: flex;
	margin: 1rem 0rem;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: space-around;
	height: 5rem;
	align-items: center;
}

@media only screen and (max-width: 800px) {
	.userOptions {
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin: 1rem 0rem 0rem 0rem;
		height: fit-content;
	}
}
