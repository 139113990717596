/* containers */
.wrapForm-create-w100 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: inherit;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0rem 1.8rem;
}

.container-select-cpa {
	width: 18rem;
}

.container-options-tables {
	width: 100%;
	margin: auto;
}

.tab-options-tables {
	width: 100%;
	padding: 0.5rem;
	background: #8b909c;
	border-radius: 0px;
	font-size: 1rem;
	color: white;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container-table-content {
	width: 100%;
	border: 0px;
	border-radius: 0px;
}

.container-tech-assign {
	width: 90%;
	margin: auto;
	min-width: 800px;
	padding: 0rem;
	margin-bottom: 1rem;
}

/* btn-actions-table product services */
.btn-action-ps {
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	outline: 0;
	border: 0;
	user-select: none;
	vertical-align: middle;
	-webkit-appearance: none;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.75;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	min-width: 64px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	color: #fff;
	border-radius: 10px;
	z-index: 1;
	width: fit-content;
	margin: 0px;
	padding: 0px 0.6rem;
	background-color: var(--inactive-color);
}

/* btn-actions-table select tech */
.btn-action-select-tech {
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	outline: 0;
	border: 0;
	user-select: none;
	vertical-align: middle;
	-webkit-appearance: none;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	line-height: 1.75;
	letter-spacing: 0.02857em;
	min-width: 64px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	color: var(--text-dark);
	border-radius: 10px;
	font-size: 0.9rem;
	font-weight: bold;
	z-index: 1;
	width: fit-content;
	margin: 1.5px 0px;
	padding: 0px 0.6rem;
	background: var(--background-primary-linear);

	cursor: pointer;
}

/* p styles */
.p-clone-action {
	font-size: 1rem;
	font-weight: 500;
	color: gray;
	margin-right: 0.5rem !important;
	cursor: pointer;
}

.p-clone-action:hover {
	text-decoration: underline;
}

/* selects styles */
.select-type {
	background: transparent;
	border: 0px;
	color: var(--dark-gray);
	font-weight: 500;
	width: 155px;
}

@media (min-width: 900px) {
	.container-tech-assign {
		padding: 2rem;
	}

	.wrapForm-create-w100 {
		flex-direction: column;
	}

	.container-select-cpa {
		width: 100%;
	}
}
