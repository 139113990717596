body::-webkit-scrollbar {
	display: none;
}

.sidenav {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	top: 0;
	margin-top: 74px;
	padding-bottom: 74px;
	left: 0;
	background-color: #0ed6b5;
	overflow: scroll;
	transition: 0.5s;
	padding-top: 10px;
}

.side-nav-header {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--white);
	margin-bottom: 2rem;
	padding: 0.5rem;
}

.sidenav::-webkit-scrollbar {
	width: 10px;
}

.sidenav::-webkit-scrollbar-track {
	background-color: #e4e4e4;
	border-radius: 100px;
}

.sidenav::-webkit-scrollbar-thumb {
	border-radius: 100px;
	border: 2px solid transparent;
	background-clip: content-box;
	background-color: #8070d4;
}

.sidenav a {
	padding: 10px 5px 10px 0px;
	text-decoration: none;
	font-size: 18px;
	color: #8b909c;
	display: block;
	transition: 0.3s;
	width: 100%;
}

.sidenav a:hover {
	color: white;
	background-color: rgba(1, 143, 203, 0.699);
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}

.active {
	background-color: rgba(1, 143, 203, 0.699);
	color: white !important;
}

.notActive {
	color: #8b909c;
	background: #f8f8f8;
}

.sidenav .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
}

#main {
	transition: margin-left 0.5s;
	padding: 0px;
}

@media screen and (max-height: 450px) {
	.sidenav {
		padding-top: 15px;
	}
	.sidenav a {
		font-size: 18px;
	}
}

.dropdown__option a {
	text-decoration: none;
	color: #8b909c;
	padding-left: 30px;
	font-size: 15px;
	font-weight: 600;
	width: 100%;
}

.dropdown {
	width: 100%;
	background: #f8f8f8;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
}

.dropdown__title {
	height: 100%;
	background: #0ed6b5;
	width: 100%;
	color: white;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding-left: 10px;
	text-align: left;
}

.dropdown__title::before {
	float: right;
	right: 10%;
	margin-right: 10px;
	/* content: ">"; */
}

.dropdown__title:hover {
	background-color: #0aa48a;
}

/* .slide {
  clear: both;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  text-align: left;
  transition: max-height 0.3s ease;
  list-style: none;
  padding: 0;
  margin: 0;
} */

.slide {
	clear: both;
	width: 100%;
	text-align: left;
	transition: max-height 0.3s ease;
	list-style: none;
	padding: 0;
	margin: 0;
	height: 200px;
	overflow: scroll;
	overflow-x: hidden;
}

.sline {
	margin: 0 auto;
	width: 100%;
}

.open__button {
	position: absolute;
	opacity: 0;
	height: 0px;
}

.close__button {
	max-height: 400px;
	transition: max-height 0.5s ease;
}
