.form-responsive-container-buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
}

.form-responsive-container-buttons button {
	margin: 1rem 0rem;
}

@media only screen and (max-width: 900px) {
	.form-responsive-container-buttons {
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		margin: 1rem 0rem;
	}
}
