@import '~react-agenda/build/styles.css';
@import '~react-datetime/css/react-datetime.css';

/* global displya */
.display-flex {
	display: flex;
	align-items: center;
}

/* global paddings */
.padding-g1 {
	padding: 1rem;
}

/* common styles */
.g-pointer {
	cursor: pointer;
}

.link-search-container {
	display: flex;
	width: 90%;
	height: 4rem;
	align-items: center;
	justify-content: flex-start;
	margin: auto;
}

.link-search {
	color: var(--text-dark);
	text-decoration: underline;
}

.modal-detail-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.searchButtonContainerService {
	background-color: #ffc954;
	border-radius: 25%;
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.searchDetailsButtonService {
	color: #0aa48a;
	text-decoration: underline;
	cursor: pointer;
	font-weight: bold;
}

.searchButtonService {
	color: #58585b;
	cursor: pointer;
	/* margin-left: 1rem; */
	padding: 0.3rem 1.5rem;
	font-weight: bold;
}

.wrap-input {
	width: 80%;
	margin-right: 20px;
	min-width: 250px;
}

/* modal css custom */
.customModal {
	background: var(--white);
	max-width: 80vw;
	width: 100%;
	margin: 1rem 0rem;
}

.customModal-90 {
	background: var(--white);
	max-width: 90vw;
	width: 100%;
}

/* bg modal */
.customOverlay {
	background: rgba(80, 80, 80, 0.7);
}

.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal {
	animation-fill-mode: forwards !important;
}

/* inputs */
.input-label-icon {
	width: 100%;
	position: relative;
	display: flex;
}

.input-label-style {
	width: 100%;
	border: 0;
	background: var(--white);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-right: none;
	/* height: 10px; */
	border-radius: 50px 0px 0px 50px;
	padding: 0.5rem 2rem;
	outline: none;
	color: #9dbfaf;
	height: 36px !important;
}

.input-label-style:focus {
	color: black;
}

.input-select-option-custom {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	color: var(--text-dark);
	font-weight: 400;
	font-family: inherit;
	margin: 0.5rem 0rem;
	width: 300px;
	height: 65px;
}

/* btn styles */
.btn-action-style {
	width: 40px;
	height: 36px !important;
	background: var(--light-gray);
	/* border: 0.1rem solid gray; */
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	color: #fff;
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	font-size: 20px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn-action-style-s {
	background: linear-gradient(113.52deg, #ffc954 34.84%, #e9b440 122.42%);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border: 0;
	border-radius: 50px;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: bold;
	width: fit-content;
	padding: 0rem 1rem;
	cursor: pointer;
	max-width: 200px;
}

.p-text-primary {
	font-size: 0.9rem;
	color: var(--color-primary);
	font-weight: 600;
}

/* containers */
.container-search-modals {
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
	flex-direction: row;
	text-align: center;
}

/* search advanced */
.advance-search {
	margin-bottom: 10px;
	cursor: pointer;
}

.advance-search p {
	display: flex;
	justify-content: initial;
	align-items: center;
}

/* Text-area */
.input-default-textarea {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 10px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: 400;
	min-width: 250px;
	width: 90%;
	height: 4rem;
	resize: none;
	overflow: visible;
	/* margin-bottom: 1rem; */
}

.input-default-textarea::placeholder {
	font-family: inherit;
	font-size: 0.9rem;
	color: var(--text-dark);
}

/* boxes */
.box-40p {
	width: 40%;
}

.boxTable {
	display: flex;
	margin: 1rem 0rem;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: space-around;
	height: auto;
	align-items: center;
}

.textCenter {
	text-align: center;
}

/* margins */
.custom-margin-1000 {
	margin: 1rem 0rem 0rem 0rem !important;
}

.custom-margin-0010 {
	margin: 0rem 0rem 1rem 0rem !important;
}

.custom-margin-0001 {
	margin: 0rem 0rem 0rem 1rem !important;
}

/* postions */
.p-show-services {
	position: absolute;
	right: 7%;
	background-color: transparent;
	border: 0px;
	text-align: left;
}

/* toggles */
.container-toggle-label {
	display: flex;
	justify-content: end;
	align-items: flex-start;
	color: var(--inactive-color);
}

@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,300&family=Poppins:wght@300&display=swap');

:root {
	--body-bg: #f5f8ff;
	--box-bg: #fff;
	--input-bg: #f5f8ff;
	--txt-color: #2f2d2f;
	--txt-second-color: #ccc;
	--border-color: #0da68a;
	--box-shadow: rgb(149, 157, 165, 0.2) 0px 8px 24px;
}

/* schedule */
.schedule-avanced-search {
	height: fit-content;
	width: 100%;
	margin: 1rem 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.generalStyles {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	line-height: 1.5;
	color: var(--txt-color);
	display: flex;
	justify-content: center;
	padding-top: 25px;
	height: 43vh;
}

.box {
	overflow: auto;
	padding: 30px;
	border-radius: 20px;
}

.formsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-top: 50px;
	overflow: auto;
}

.formsContainer div {
	flex-direction: column !important;
}

.formsContainer input {
	margin-right: 10px;
}

.formsContainer.btnGuardarFactura {
	display: flex !important;
	flex-direction: column !important;
}

.form-responsive-information__option {
	overflow: visible !important;
}

.form-responsive-information__option input {
	margin-left: 50px;
}

.formsContainer label {
	font-family: inherit;
	color: black;
	font-weight: bold;
	text-align: center;
}

.ticketSection {
	display: flex !important;
	flex-direction: row !important;
}

#radioTicket,
#radioExp {
	accent-color: var(--border-color);
}

.card {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 1px solid var(--border-color);
	border-radius: 0;
	box-shadow: 0px 1px 4px rgb(70, 70, 70);
	width: 800px;
	margin: 10px 10px 30px 0px;
}

.btnDetalle {
	appearance: none;
	background-color: white;
	border: 1px solid var(--border-color);
	/* border-radius: 4px; */
	box-sizing: border-box;
	color: var(--txt-color);
	cursor: pointer;
	display: inline-block;
	margin: 10px;
	height: 32px;
	width: 170px;
}

.textBlock {
	margin: 10px;
}

.btnAdjuntarContainer {
	position: relative;
	/* display: flex;
    justify-content: center; */
}

.btnAdjuntarContainer input {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.btnAdjuntarNQR {
	border: none !important;
	font-family: inherit !important;
	font-weight: 100 !important;
	vertical-align: auto !important;
	padding: 0px 10px !important;
	margin-top: 20px;
}

/* tooltip */
.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 150px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: 50%;
	margin-left: -10px;
}

.tooltip .tooltiptext::after {
	/* content: ""; */
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

@media only screen and (max-width: 1050px) {
	.custom-margin-0100-s {
		margin: 1rem 0rem 0rem 0rem !important;
	}

	.container-toggle-label {
		justify-content: center;
	}
}

@media only screen and (max-width: 1000px) {
	.gestionCards {
		width: 790px;
	}

	.card {
		width: 700px;
	}
}

@media only screen and (max-width: 940px) {
	.btnAdjuntarContainer {
		position: relative;
		display: flex;
		justify-content: center;
	}

	.editor {
		text-align: center;
	}
}

@media only screen and (max-width: 900px) {
	.box-40p {
		width: 100%;
	}

	.container-search-modals {
		flex-direction: column;
		margin-top: 0.7rem;
	}

	.boxTable {
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin: 1rem 0rem 0rem 0rem;
		height: fit-content;
	}

	.formsContainer {
		flex-direction: column;
	}

	.gestionCards {
		width: 700px;
	}

	.card {
		width: 635px;
	}
}

@media only screen and (max-width: 800px) {
	.wrap-input {
		width: 100% !important;
		margin-right: 0px;
	}

	.searchTerm {
		margin-bottom: 0.5rem;
	}

	.gestionCards {
		width: 650px;
	}

	.card {
		width: 620px;
	}
}

@media only screen and (max-width: 700px) {
	.customModal {
		max-width: 95vw;
	}
}

.map-container {
	width: 100%;
	height: 88vh;
}

@media only screen and (max-width: 600px) {
	.gestionCards {
		width: 450px;
	}

	.card {
		width: 400px;
	}

	.input-select-option-custom {
		width: 250px;
	}
}

@media only screen and (max-width: 500px) {
	.gestionCards {
		width: 372px;
	}

	.card {
		width: 340px;
		height: 100px;
	}
}

@media only screen and (max-width: 400px) {
	.gestionCards {
		width: 335px;
	}

	.card {
		width: 310px;
		height: 100px;
	}
}

@media only screen and (max-width: 370px) {
	.gestionCards {
		width: 320px;
	}

	.card {
		width: 295px;
		height: 120px;
	}
}

@media only screen and (max-width: 300px) {
	.gestionCards {
		width: 260px;
	}

	.card {
		width: 240px;
		height: 200px;
	}

	.btnDetalle {
		height: 50px;
	}
}

.css-ofbd0u .MuiOutlinedInput-root {
	padding: 0px !important;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
	padding: 1px !important;
}

.css-1uvydh2 {
	padding: 8px !important;
	border: 6px none !important;
}

.css-igs3ac {
	border: 0px none !important;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2) !important;
	padding: 5px !important;
}
