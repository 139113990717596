.edit-save-button {
	background: linear-gradient(113.52deg, #ffc954 34.84%, #e9b440 122.42%);
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border: 0;
	border-radius: 50px;
	padding: 0.5rem 3rem;
	font-family: inherit;
	color: #58585b;
	font-weight: bold;
}

.cancel-save-button {
	background: white;
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: #58585b;
	font-weight: bold;
	margin: 0rem 1rem;
}

.input-edit {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	font-family: inherit;
	color: var(--text-dark);
	font-weight: 400;
	margin: 1rem 0rem;
	width: 18rem;
}

.input-edit-fix {
	background: var(--white);
	border: 0;
	box-shadow: 0px 4px 8px rgba(0, 174, 142, 0.2);
	border-radius: 50px;
	padding: 0.5rem 2rem;
	color: var(--text-dark);
	font-weight: 400;
	font-family: inherit;
	min-width: fit-content;
	margin: 1rem 0rem;
	width: 250px;
}

.switch {
	position: relative;
	display: inline-block;
	width: 3.3rem;
	height: 26px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	padding: 0.7rem;
	margin-top: 2px;
	margin-left: 4px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 1rem;
	width: 1rem;
	left: 0px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider-pressed {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	padding: 0.7rem;
	margin-top: 2px;
	margin-left: 4px;
	background-color: rgba(2, 235, 193, 1);
	box-shadow: 0 0 1px rgba(2, 235, 193, 1);
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
	border-radius: 2rem;
}

/* Rounded sliders */
.slider.round {
	border-radius: 2rem;
}

.slider.round:before {
	border-radius: 50%;
	margin-left: 3px;
}

.button-spacing {
	margin-top: 1rem;
}

.form-group {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 50%;
}

@media (max-width: 900px) {
	.input-edit {
		width: 100%;
	}
}

.role-item {
	margin-left: 3px;
	margin-bottom: 3px;
}
