.App {
	text-align: center;
}
.prosidebar {
	background-color: white !important;
}

.sidebar {
	background-color: cyan;
}
.bar {
	position: absolute;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.Image-Logo-Screen {
	height: 40vmin;
	margin-top: 150px;
	margin-left: 150px;
	margin-bottom: 150px;
}

.Image-Logo-Screen2 {
	height: 8vmin;
}

.formLogin {
	background-color: lightgrey;
	height: 550px;
}
.text {
	text-align: center;
	font-weight: bold;
	margin-bottom: 0;
}
.textFooter {
	text-align: left;
	margin-bottom: 0;
	margin-left: 20px;
}
.inputs {
	border-radius: 10px;
	box-shadow: 5px 5px 5px lightgrey;
	width: 300px;
	height: 40px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.buttons {
	background-color: gold;
	border: none;
	font-weight: bold;
	border-radius: 20px;
	width: 200px;
	height: 40px;
}
.containerLogin {
	background-color: white;
	display: flex;
	border-radius: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 400px;
	height: 400px;
	margin-left: 80px;
}

.Footer {
	background-color: #282c34;
	color: white;
	display: flex;
	flex-direction: row;
	height: 80px;
}

.containerRow {
	display: flex;
	flex-direction: column;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#header {
	position: absolute;
	width: 220px;
}
#header .pro-sidebar {
	height: 100vh;
}
#header .closemenu {
	color: #000;
	position: absolute;
	right: 0;
	z-index: 9999;
	line-height: 20px;
	border-radius: 50%;
	font-weight: bold;
	font-size: 22px;
	top: 55px;
	cursor: pointer;
}
#header .pro-sidebar {
	width: 100%;
	min-width: 100%;
}
#header .pro-sidebar.collapsed {
	width: 80px;
	min-width: 80px;
}
#header .pro-sidebar-inner {
	background-color: darkcyan;
	box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
	overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
	font-size: 20px;
	padding: 0 20px;
	color: #fff;
	font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
	padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
	color: #fff;
	margin: 10px 0px;
	font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
	background-color: #fbf4cd;
	color: #000;
	border-radius: 3px;
}
#header
	.pro-sidebar-inner
	.pro-sidebar-layout
	ul
	.pro-inner-item
	.pro-icon-wrapper
	.pro-item-content {
	color: white;
}

#header .pro-inner-list-item .pro-menu-item .pro-inner-item .pro-item-content {
	color: #000;
}
#header .logo {
	padding: 20px;
}

#header .pro-inner-list-item {
	background-color: white;
}
.containerRow {
	display: flex;
	flex-direction: row;
	width: 50px;
}
@media only screen and (max-width: 720px) {
	/* html {
    overflow: hidden;
  } */
}
