main {
	padding: 0rem !important;
	overflow: auto;
}

.o-container-login {
	width: 100%;
	height: 85vh;
	display: flex;
	color: black;
}

.o-enter-logo {
	display: flex;
	align-items: center;
	background-color: white;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 85vh;
	justify-content: center;
	text-align: center;
	flex-direction: column;
}

.o-enter-logo > img {
	width: 70%;
	height: auto;
	object-fit: contain;
}

.o-enter-user {
	display: flex;
	align-items: center;
	background-color: #f1f1f1;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 85vh;
	justify-content: center;
	text-align: center;
	flex-direction: column;
}

.o-card-login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	text-align: center;
	background-color: white;
	border-radius: 36px;
	height: 50vh;
	min-height: 450px;
	width: 80%;
	max-width: 400px;
}

.o-login-form {
	text-decoration: none;
	width: 100%;
	height: fit-content;
}

.o-login-form p {
	padding: 1rem;
}

.o-forget-password {
	color: var(--text-secondary);
	cursor: pointer;
	font-weight: 500;
}
.o-forget-password {
	text-decoration: underline;
}

.eye-icon {
	fill: var(--light-gray);
	cursor: pointer;
	position: absolute;
	right: 40px;
	top: 44%;
	transform: translate(-50%);
}

.language-change {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--white);
	margin-bottom: 2rem;
	padding: 0.5rem;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
}

.language {
	color: black;
	margin-left: 10px;
	margin-top: 0;
}

@media (max-width: 780px) {
	main {
		width: 100%;
		height: 70rem;
		display: flex;
		flex-direction: column;
		color: black;
	}

	.eye-icon {
		right: 80px;
	}

	.o-card-login {
		margin: 1rem;
	}

	.o-enter-logo {
		display: none;
		height: 100vh;
		min-height: 100%;
	}

	.o-enter-user {
		height: 100vh;
		min-height: 100%;
	}
}
