table.scroll {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

table.scroll tbody {
	display: inline-block;
	height: 100px;
	overflow-y: auto;
	overflow-x: hidden;
}

.scroll tbody {
	width: 100%;
}

.scroll tr {
	width: 100%;
	display: block;
	padding: 0px;
	margin: 0px;
}

.scroll tr button {
	width: 100%;
	padding: 5px;
	border-radius: 5px;
	border: none;
	text-align: left;
	padding-left: 10px;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
}

.scroll tr button:hover {
	background-color: #7e8491;
	color: white;
}

.scroll td {
	width: 100%;
}

.searchRol {
	position: relative;
	width: 100%;
	border-radius: 10px;
	bottom: 0px;
	z-index: 10;
}
