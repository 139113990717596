.table-minimal-container {
	width: 90%;
	overflow: auto;
	overflow-y: hidden;
}

.table-minimal {
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 0.9rem;
	font-weight: normal;
	line-height: 1.5;
	/* margin-bottom: 1.5rem; */
	width: 100%;
}

.minimal-table__actions {
	text-align: center;
}
