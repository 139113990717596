.table-container {
	width: 97%;
	margin: 0 auto;
}

.containerRow {
	display: flex;
	flex-direction: row;
	width: 50px;
}

.title-page {
	text-align: center;
	color: #0ed6b5;
}

p {
	margin: 0px !important;
}

td,
tr,
th {
	border: 1px solid rgba(0, 174, 142, 0.2) !important;
}

th {
	padding: 0px 3px 0px 3px !important;
}

td {
	margin: 0px !important;
	padding: 0px 3px 0px 3px !important;
}

.role-container-info {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1rem 0rem;
}

@media only screen and (max-width: 800px) {
	.role-container-info {
		flex-direction: column;
		padding: 1rem 0rem 0rem 0rem;
	}
}
