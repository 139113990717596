.footer {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
	background-color: var(--background-gray);
	color: var(--white);
	height: 15vh;
}

.footer-container-row {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	height: fit-content;
	text-align: left;
}

.footer-container-row h2 {
	margin: 0px;
	color: var(--white);
}

.footer-container-row a {
	color: var(--white);
	text-decoration: underline;
	margin: 0px;
}

.no-underline {
	text-decoration: none !important;
}

.email-footer {
	color: var(--background-gray) !important;
	background-color: var(--white);
	padding: 5px 8px;
	border-radius: 30px;
	margin-left: 0.5rem !important;
}

@media only screen and (max-width: 780px) {
	.footer {
		height: 50vh;
	}
	.footer-container-row {
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
		min-width: 300px;
	}
}

@media only screen and (max-width: 600px) {
	.footer {
		height: 60vh;
	}

	.footer-container-row h2 {
		font-size: 1.4rem;
	}

	.footer-container-row p {
		font-size: 0.9rem;
	}
	.footer-container-row a {
		font-size: 0.9rem;
	}
}

@media only screen and (max-width: 300px) {
	.footer-container-row {
		min-width: fit-content;
	}
	.footer-container-row h2 {
		font-size: 1rem;
	}

	.footer-container-row p {
		font-size: 0.5rem;
	}
	.footer-container-row a {
		font-size: 0.5rem;
	}
}
