/* containers */
.container-ps {
	display: flex;
	width: 100%;
	padding: 1rem;
	justify-content: space-between;
	align-items: center;
}

.container-ps-modal-ud {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.container-ps-modal-ud label {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.container-ps-modal-ud label span {
	width: 200px;
	font-weight: bold;
}

@media (max-width: 660px) {
	.container-ps {
		flex-direction: column;
	}

	.container-ps-modal-ud label {
		flex-direction: column;
		text-align: center;
	}
}
