.calendarContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	float: left;
	width: 100%;
	overflow-x: scroll;
	margin: 2rem 0rem;
	padding-left: 1rem;
}

.calendarContainer > table {
	width: 90%;
}
.calendarContainer > table tr {
	position: relative;
}

.calendarContainer > table > tr td:nth-child(1) {
	width: 20%;
	height: 6rem;
	border: none auto none none !important;
}

.servicesContainer {
	display: flex;
}

.techniciaCard {
	color: var(--text-primary);
	font-weight: bold;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 12px;
	width: 180px;
	margin: auto 8px;
}

.technicianServiceCard {
	border-radius: 10px;
	width: 300px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	background-color: #ffa654;
	/* padding: 4px 12px; */
	font-weight: 400;
	color: #000;
	margin: 6px 9px 6px 6px;
}

.serviceCardDateTimeContainer {
	display: flex;
	flex-direction: column;
	margin-top: 6px;
	margin-bottom: 4px;
}

.serviceCardDateTimeHeader {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	/* justify-content: space-around; */
	font-weight: 600;
}

.serviceCardDateTimeRow {
	display: flex;
	justify-content: flex-start;
	column-gap: 8px;
	padding: 0 0 0 6px;
}

.serviceCardDateTimeRow > p:nth-child(1) {
	color: rgb(88, 88, 88);
	font-size: 14px;
	font-weight: 500;
}
.serviceCardDateTimeRow > p:nth-child(2) {
	font-size: 14px;
	font-weight: 600;
}
.serviceCardDateTimeRow > p:nth-child(3) {
	font-size: 14px;
	font-weight: 600;
}
/* .serviceCardTable {
  border: none !important;
}

.serviceCardTable > tr > th {
  text-align: center;
}

.serviceCardTable > tr > td {
  height: fit-content !important;
} */

.serviceCardDateContainer {
	margin: 4px 0;
}

.dataContainer {
	margin-bottom: 2px;
}

.dataContainer > div {
	display: flex;
}

.cardDataText {
	padding-left: 2px;
	font-weight: 600;
}

.boldText {
	font-weight: 600;
}

.headerContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* margin: 2px 0; */
}

.TickerNumberType {
	display: flex;
	flex-direction: column;
	width: 50%;
	/* border: 1px solid #e0e0e0; */
	/* border-style: none solid solid none; */
	/* border-radius: 0 0 12px 0; */
	/* background-color: #f7f7f7; */
	/* padding: 4px 8px; */
}

.TickerNumberType > p:nth-child(1) {
	font-size: 14px;
	font-weight: 500;
	padding: 6px 0 0 6px;
}

.TickerNumberType > p:nth-child(2) {
	font-size: 14px;
	padding: 0 0 0 6px;
}

.serviceStatus {
	padding: 4px;
	color: #fff;
	font-size: 12px;
	text-align: center;
}
.serviceStatus > p {
	padding: 4px 8px;
	border-radius: 10px;
}

.filtersContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 1rem;
}

.btnFiltersContainer {
	margin-left: 1rem;
}

.btnFiltersContainer button {
	width: 10vw;
}

@media (max-width: 900px) {
	.filtersContainer {
		flex-direction: column;
		padding: 1rem;
	}

	.btnFiltersContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 90%;
		margin: auto;
	}

	.btnFiltersContainer button {
		width: 60%;
	}
}
